import { HeadingLevelProvider } from "@jfrk/react-heading-levels";
import { IDContextProvider } from "@jfrk/react-id";
import React, { createContext } from "react";

import { AlertProvider } from "./hooks/alert";
import { MatomoScript } from "./hooks/matomo";
import { RekaiProvider } from "./hooks/rekai";
import { StoreProvider } from "./hooks/store";

export const pageWrapperContext = createContext();
export const PageWrapperContextProvider = pageWrapperContext.Provider;

export default function PageWrapper({ context, children }) {
  return (
    <>
      <PageWrapperContextProvider value={context}>
        <HeadingLevelProvider>
          <IDContextProvider>
            <StoreProvider
              initialState={{
                cookieConsent: { answer: null, answeredAt: null },
              }}
            >
              <RekaiProvider
                scriptSrc={process.env.GATSBY_REKAI_SCRIPT_SRC}
                defaultOverwrite={{
                  projectid: process.env.GATSBY_REKAI_PUBLIC_ID || null,
                  srek: process.env.GATSBY_REKAI_SECRET_KEY || null,
                }}
              >
                {!process.env.GATSBY_DISABLE_ALERT ? (
                  <AlertProvider>{children}</AlertProvider>
                ) : (
                  children
                )}
              </RekaiProvider>
            </StoreProvider>
          </IDContextProvider>
        </HeadingLevelProvider>
      </PageWrapperContextProvider>
      {process.env.GATSBY_MATOMO_URL && <MatomoScript />}
    </>
  );
}
