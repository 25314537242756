const WP_URL = `${process.env.GATSBY_WORDPRESS_URL}`;

function escapeRegex(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const WP_URL_REGEX = new RegExp(
  `^${escapeRegex(WP_URL.replace(/\/wp$/, ""))}(\\/wp)?\\/`,
);
const WP_CONTENT_URL_REGEX = new RegExp(
  `^${escapeRegex(WP_URL.replace(/\/wp$/, ""))}(\\/wp)?\\/wp-content\\/`,
);

export function normalizeWordpressUrl(url) {
  if (typeof url !== "string") {
    return url;
  }
  if (WP_CONTENT_URL_REGEX.test(url)) {
    return url;
  }
  return url.replace(WP_URL_REGEX, "/");
}
