// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-tillganglighetsredogorelse-js": () => import("./../../../src/pages/tillganglighetsredogorelse.js" /* webpackChunkName: "component---src-pages-tillganglighetsredogorelse-js" */),
  "component---src-templates-info-page-template-js": () => import("./../../../src/templates/InfoPageTemplate.js" /* webpackChunkName: "component---src-templates-info-page-template-js" */),
  "component---src-templates-main-archive-page-template-js": () => import("./../../../src/templates/MainArchivePageTemplate.js" /* webpackChunkName: "component---src-templates-main-archive-page-template-js" */),
  "component---src-templates-month-archive-page-template-js": () => import("./../../../src/templates/MonthArchivePageTemplate.js" /* webpackChunkName: "component---src-templates-month-archive-page-template-js" */),
  "component---src-templates-post-page-template-js": () => import("./../../../src/templates/PostPageTemplate.js" /* webpackChunkName: "component---src-templates-post-page-template-js" */),
  "component---src-templates-project-page-template-js": () => import("./../../../src/templates/ProjectPageTemplate.js" /* webpackChunkName: "component---src-templates-project-page-template-js" */),
  "component---src-templates-site-index-template-js": () => import("./../../../src/templates/SiteIndexTemplate.js" /* webpackChunkName: "component---src-templates-site-index-template-js" */),
  "component---src-templates-start-page-template-js": () => import("./../../../src/templates/StartPageTemplate.js" /* webpackChunkName: "component---src-templates-start-page-template-js" */),
  "component---src-templates-year-archive-page-template-js": () => import("./../../../src/templates/YearArchivePageTemplate.js" /* webpackChunkName: "component---src-templates-year-archive-page-template-js" */)
}

