import { init } from "@jeanfredrik/yett";
import React from "react";
import rehypeParse from "rehype-dom-parse";
import "url-polyfill";

import PageWrapper from "./src/PageWrapper";
import createHTMLProcessor from "./src/utils/html";

import "./src/styles/index.scss";

const htmlProcessor = createHTMLProcessor({ rehypeParse });

let whitelist = [];
let blacklist = [];

if (window.location.host !== "") {
  // Allow all scripts on same origin
  whitelist.push(`/*`);
  whitelist.push(`${window.location.origin}/*`);

  // Allow all Chrome extensions
  whitelist.push(`chrome*`);

  // Allow Rek.ai
  whitelist.push(`https://static.rek.ai/*`);
  whitelist.push(`https://*.arcgis.com/*`);
  whitelist.push(`https://*.arcgisonline.com/*`);
}

//convert strings to regex and escape special characters
whitelist = whitelist.length
  ? whitelist.map(function (domain) {
      return new RegExp(
        `^${domain
          .replace(/[.+?^${}()|[\]\\]/g, "\\$&")
          .replace(/\*/g, ".*")}$`,
      );
    })
  : null;

//convert strings to regex and escape special characters
blacklist = blacklist.length
  ? blacklist.map(function (domain) {
      return new RegExp(
        `^${domain
          .replace(/[.+?^${}()|[\]\\]/g, "\\$&")
          .replace(/\*/g, ".*")}$`,
      );
    })
  : null;

init({
  blacklist,
  whitelist,
});

export const onServiceWorkerUpdateReady = () => {
  console.info(
    "This application has been updated. The page will reload now to serve the latest version.",
  );
  window.location.reload();
};

export function wrapRootElement({ element }) {
  return <PageWrapper context={{ htmlProcessor }}>{element}</PageWrapper>;
}

export function shouldUpdateScroll({ routerProps, prevRouterProps }) {
  return (
    routerProps?.location?.pathname !==
      prevRouterProps?.prevLocation?.pathname ||
    routerProps?.location?.hash !== prevRouterProps?.prevLocation?.hash
  );
}

/**
 * Matomo
 */
let first = true;

function getDuration() {
  const start = window.start || new Date();
  const now = new Date();
  const difference = now.getTime() - start.getTime();

  if (difference === 0) {
    return null;
  }

  return difference;
}

export const onRouteUpdate = ({ location, prevLocation }, pluginOptions) => {
  window._paq = window._paq || [];

  const { _paq } = window;
  const url = location && location.pathname + location.search + location.hash;
  const prevUrl =
    prevLocation &&
    prevLocation.pathname + prevLocation.search + prevLocation.hash;

  const { trackLoad = true } = pluginOptions;

  // document.title workaround stolen from:
  // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
  const sendPageView = () => {
    const { title } = document;

    prevUrl && _paq.push(["setReferrerUrl", prevUrl]);
    _paq.push(["setCustomUrl", url]);
    _paq.push(["setDocumentTitle", title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(["trackAllContentImpressions"]);

    if (process.env.NODE_ENV !== "production") {
      console.debug(`[Matomo] Page view for: ${url} - ${title}`);
    }
  };

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, 0);
  setTimeout(sendPageView, delay);

  if (first) {
    first = false;

    if (trackLoad) {
      _paq.push([
        "trackEvent",
        "javascript",
        "load",
        "duration",
        getDuration(),
      ]);
    }

    if (process.env.NODE_ENV !== "production") {
      console.debug(`[Matomo] Tracking duration for: ${url}`);
    }
  }

  return null;
};
