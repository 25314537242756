import { useState, useCallback, useLayoutEffect } from "react";

function getSize(el, defaultSize) {
  if (!el) {
    return defaultSize;
  }

  return {
    width: el.clientWidth,
    height: el.clientHeight,
  };
}

export default function useComponentSize(
  ref,
  defaultSize = { width: 0, height: 0 },
) {
  const [ComponentSize, setComponentSize] = useState(
    getSize(ref ? ref.current : {}, defaultSize),
  );

  var handleResize = useCallback(() => {
    if (ref.current) {
      setComponentSize(getSize(ref.current));
    }
  }, [ref]);

  useLayoutEffect(
    function () {
      if (!ref.current) {
        return;
      }

      handleResize();

      if (typeof ResizeObserver === "function") {
        var resizeObserver = new ResizeObserver(function () {
          handleResize();
        });
        resizeObserver.observe(ref.current);

        return () => {
          resizeObserver.disconnect(ref.current);
          resizeObserver = null;
        };
      } else {
        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }
    },
    [ref.current],
  );

  return ComponentSize;
}
